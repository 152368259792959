export default {
  1: [
    { code: 'math1', path: '/storage/support/video/1/1.mp4', num: 1, name: 'Видео 1. Вход на платформу. Начало работы' },
    { code: 'math2', path: '/storage/support/video/1/2.mp4', num: 2, name: 'Видео 2. Раздел с аватаркой' },
    { code: 'math3', path: '/storage/support/video/1/3.mp4', num: 3, name: 'Видео 3. Раздел Тренажёры' },
    { code: 'math4', path: '/storage/support/video/1/4.mp4', num: 4, name: 'Видео 4. Раздел Сетевые игры' },
    { code: 'math5', path: '/storage/support/video/1/5.mp4', num: 5, name: 'Видео 5. Раздел Дженфон' },
    { code: 'math6', path: '/storage/support/video/1/6.mp4', num: 6, name: 'Видео 6. Раздел Домашнее задание' },
    { code: 'math7', path: '/storage/support/video/1/7.mp4', num: 7, name: 'Видео 7. Верхнее меню' }
  ],
  2: [
    { code: 'reader1', path: '/storage/support/video/2/1.mp4', num: 1, name: 'Видео 1. Вход на платформу. Начало работы' },
    { code: 'reader2', path: '/storage/support/video/2/2.mp4', num: 2, name: 'Видео 2. Раздел с аватаркой' },
    { code: 'reader3', path: '/storage/support/video/2/3.mp4', num: 3, name: 'Видео 3. Раздел Тренажёры' },
    { code: 'reader4', path: '/storage/support/video/2/4.mp4', num: 4, name: 'Видео 4. Раздел Сетевые игры' },
    { code: 'reader5', path: '/storage/support/video/2/5.mp4', num: 5, name: 'Видео 5. Раздел Дженфон' },
    { code: 'reader6', path: '/storage/support/video/2/6.mp4', num: 6, name: 'Видео 6. Раздел Домашнее задание' },
    { code: 'reader7', path: '/storage/support/video/2/7.mp4', num: 7, name: 'Видео 7. Верхнее меню' }
  ],
  3: [
    { code: 'mental1', path: '/storage/support/video/3/1.mp4', num: 1, name: 'Видео 1. Вход на платформу. Начало работы' },
    { code: 'mental2', path: '/storage/support/video/3/2.mp4', num: 2, name: 'Видео 2. Раздел с аватаркой' },
    { code: 'mental3', path: '/storage/support/video/3/3.mp4', num: 3, name: 'Видео 3. Раздел Тренажёры' },
    { code: 'mental4', path: '/storage/support/video/3/4.mp4', num: 4, name: 'Видео 4. Раздел Сетевые игры' },
    { code: 'mental5', path: '/storage/support/video/3/5.mp4', num: 5, name: 'Видео 5. Раздел Дженфон' },
    { code: 'mental6', path: '/storage/support/video/3/6.mp4', num: 6, name: 'Видео 6. Раздел Домашнее задание' },
    { code: 'mental7', path: '/storage/support/video/3/7.mp4', num: 7, name: 'Видео 7. Верхнее меню' }
  ]
}
