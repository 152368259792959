<template>
  <div id="app" @contextmenu.prevent>
    <component :is="layout">
      <router-view/>
    </component>

    <AppSpinner />
    <notifications position="bottom right" width="350" />
    <ContextMenu ref="contextMenu" />
    <AppConfirm ref="confirm" />
    <AppImageViewer ref="imageViewer" />

    <Suspense>
      <Modal ref="modal" />
    </Suspense>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, watch, ref, provide } from 'vue'
import { useStore } from 'vuex'
import { useTitle } from '@vueuse/core'
import localizeText from '@/locales/localize'

import EmptyLayout from './layouts/EmptyLayout'
import TrainerLayout from './layouts/TrainerLayout'
import StudentLayout from './layouts/StudentLayout'
import LoadingLayout from './layouts/LoadingLayout'
import ItLayout from './layouts/ItLayout'

import AppSpinner from '@/app/base/components/AppSpinner.vue'
import ContextMenu from '@/app/base/components/AppContextMenu.vue'
import Modal from '@/app/base/components/AppModal.vue'
import AppConfirm from '@/app/base/components/AppConfirm.vue'
import AppImageViewer from '@/app/base/components/AppImageViewer.vue';

import useSocketsLikes from '@/app/base/composables/useSocketsLikes';
import { notify } from '@kyvg/vue3-notification'

export default {
  components: {
    EmptyLayout,
    TrainerLayout,
    StudentLayout,
    ItLayout,
    AppSpinner,
    LoadingLayout,
    ContextMenu,
    Modal,
    AppConfirm,
    AppImageViewer
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const user = computed(() => store.state.user)

    const contextMenu = ref(null)
    const modal = ref(null)
    const confirm = ref(null)
    const imageViewer = ref(null)

    const layout = computed(() => (route.meta.layout || 'loading') + '-layout')

    const title = computed(() => localizeText('AppTitle'))
    useTitle(title)

    provide('modal', modal)
    provide('contextMenu', contextMenu)
    provide('confirm', confirm)
    provide('imageViewer', imageViewer)

		watch(user, (val) => {
      if (val) {
        // смотреть, кто онлайн
        window.Echo.private(`online.user.${val.id}`)

        // Обновление лайков у ученика
        if (val.role_id === 3) {
          useSocketsLikes(store)

          const subjectId = store.state.student.subject?.main_id

          switch (subjectId) {
            case 1:
              // Отслеживание перехода на новый уровень профессии
              window.Echo.private(`users.${val.id}.events`).listen('.new', async(e) => {
                if (e.type === 'professionUpgraded') {
                  await store.dispatch('student/getProfession', val.id)
                  notify({
                    title: localizeText('LocationsNewLevelWarn'),
                    type: 'success'
                  })
                }
              })
              break;

            case 8:
              store.dispatch('student/getItScores')
              break;
          }
        }
      }
		})

    return {
      layout, modal, contextMenu, confirm, imageViewer
    }
  }
}
</script>
