import { updateStudentGuide } from '@/api/users'
import { computed, defineAsyncComponent, inject } from 'vue'
import { useStore } from 'vuex'
import guides from '../guides'
import { markRaw } from 'vue'

export default function useStudentClass(num) {
  const store = useStore()
  const modal = inject('modal')

  const user = computed(() => store.state.user)
  const subjectId = computed(() => store.state.student.subject.id)
  const guide = computed(() => guides[subjectId.value]?.find(g => g.num == num) || null)
  const hasGuide = computed(() => user.value.property?.guides?.includes(guide.value.code))

  async function openGuide() {
    if (!guide.value || hasGuide.value) return

    modal.value.open({
      component: markRaw(defineAsyncComponent(() => import('@/app/base/components/AppVideoPopup.vue'))),
      data: {
        name: guide.value.name,
        url: guide.value.path
      }
    })

    await updateStudentGuide(user.value.id, guide.value.code)
  }

  return {
    openGuide
  }
}
