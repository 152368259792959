import { h, resolveComponent } from 'vue';
export default [
  {
    path: '/teacher',
    name: 'TeacherView',
    meta: { layout: 'empty', userRole: [2] },
    component: () => import('@/app/teacher/views/TeacherView'),
    children: [
      {
        path: 'trainers',
        name: 'TeacherTrainersView',
        meta: { breadcrumb: 'TeacherMenuTrainers' },
        component: () => import('@/app/teacher/views/TeacherTrainersView'),
      },
      {
        path: 'classes',
        component: () => import('@/app/teacher/views/TeacherClassesView'),
        meta: { breadcrumb: 'TeacherClassesView' },
        props: true,
        children: [
          {
            path: '',
            name: 'TeacherClassesView',
            component: () => import('@/app/teacher/components/TeacherClassesList')
          },
          {
            path: 'create',
            name: 'TeacherClassesCreateView',
            meta: { breadcrumb: 'TeacherClassesCreateView' },
            props: true,
            component: () => import('@/app/teacher/views/TeacherClassesCreateView')
          },

          {
            path: ':id',
            props: true,
            meta: { breadcrumb: 'TeacherClassesItemView' },
            component: {
              render() {
                return h(resolveComponent('router-view'))
              }
            },
            children: [
              {
                path: '',
                name: 'TeacherClassesItemView',
                meta: { breadcrumb: 'TeacherClassesItemView' },
                component: () => import('@/app/teacher/views/TeacherClassesItemView')
              },
              {
                path: 'edit',
                name: 'TeacherClassesEditView',
                meta: { breadcrumb: 'TeacherClassesEditView' },
                component: () => import('@/app/teacher/views/TeacherClassesEditView')
              },
              {
                path: 'create-hw',
                name: 'TeacherHomeworkCreateView',
                meta: { breadcrumb: 'TeacherHomeworkCreateView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherHomeworkCreateView')
              },
              {
                path: 'edit-hw_:homeworkId',
                name: 'TeacherHomeworkEditView',
                meta: { breadcrumb: 'TeacherHomeworkEditView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherHomeworkEditView')
              },
              {
                path: 'create-task',
                name: 'TeacherItTaskCreateView',
                meta: { breadcrumb: 'TeacherItTaskCreateView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherItTaskCreateView')
              },
              {
                path: 'task_:taskId',
                name: 'TeacherItTaskView',
                meta: { breadcrumb: 'TeacherItTaskView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherItTaskView')
              },
              {
                path: 'materials',
                name: 'TeacherClassesMaterialsView',
                meta: { breadcrumb: 'TeacherClassesMaterialsView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherClassesMaterialsView')
              },
              {
                path: 'diaries',
                name: 'TeacherClassesDiariesView',
                meta: { breadcrumb: 'TeacherClassesDiariesView' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherClassesDiariesView')
              }
            ]
          }
        ]
      },
      {
        path: 'materials',
        name: 'TeacherMaterialsView',
        meta: { breadcrumb: 'TeacherMenuMaterials' },
        component: () => import('@/app/teacher/views/TeacherMaterialsView'),
      },
      {
        path: 'distribution',
        name: 'TeacherDistributionView',
        component: () => import('@/app/teacher/views/TeacherDistributionView'),
      },
      {
        path: 'students',
        meta: { breadcrumb: 'TeacherMenuStudents' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            path: '',
            name: 'TeacherStudentsView',
            component: () => import('@/app/teacher/views/TeacherStudentsView'),
          },
          {
            path: ':studentId',
            props: true,
            component: () => import('@/app/teacher/views/TeacherStudentsItemView'),
            meta: { breadcrumb: 'TeacherStudentsItemTitle' },
            children: [
              {
                path: '',
                name: 'TeacherStudentsItemView',
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemMenuView'),
              },
              {
                path: 'trainers',
                name: 'TeacherStudentsItemTrainersView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu1' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemTrainersView'),
              },
              {
                path: 'homework',
                name: 'TeacherStudentsItemHwView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu2' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemHwView'),
              },
              {
                path: 'online-rating',
                name: 'TeacherStudentsItemRatingOnlineView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu4' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemRatingOnlineView'),
              },
              {
                path: 'general-rating',
                name: 'TeacherStudentsItemRatingGeneralView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu5' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemRatingGeneralView'),
              },
              {
                path: 'likes',
                name: 'TeacherStudentsItemLikesView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu3' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemLikesView'),
              },
              {
                path: 'certificates',
                name: 'TeacherStudentsItemCertificatesView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu8' },
                props: true,
                component: () => import('@/app/teacher/views/TeacherStudentsItemCertificatesView'),
              },
              {
                path: 'diary',
                props: true,
                name: 'TeacherStudentsItemDiaryView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu7' },
                component: () => import('@/app/teacher/views/TeacherStudentsItemDiaryView'),
              },
              {
                path: 'likes-rating',
                props: true,
                name: 'TeacherStudentsItemRatingLikesView',
                meta: { breadcrumb: 'TeacherStudentsItemMenu6' },
                component: () => import('@/app/teacher/views/TeacherStudentsItemRatingLikesView'),
              }
            ]
          }
        ]
      },
      {
        path: 'templates',
        meta: { breadcrumb: 'TeacherMenuTemplates' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            path: '',
            name: 'TeacherTemplatesView',
            props: true,
            component: () => import('@/app/teacher/views/TeacherTemplatesView')
          },
          {
            path: 'edit',
            name: 'TeacherTemplatesEditView',
            meta: { breadcrumb: 'TeacherTemplatesEditTitle' },
            props: true,
            component: () => import('@/app/teacher/views/TeacherTemplatesEditView')
          },
        ]
      },
      {
        path: 'it-projects',
        meta: { breadcrumb: 'TeacherMenuItProjects' },
        name: 'TeacherItProjectsView',
        component: () => import('@/app/teacher/views/TeacherItProjectsView')
      }
    ]
  },
]
