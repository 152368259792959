import { computed, ref } from 'vue'

export default function useSocketsLikes(store) {
  const userId = computed(() => store.state.user.id)
  const channelName = computed(() => `users.${userId.value}.likes`)
  const likesChannel = ref(null)

  likesChannel.value = window.Echo
    .private(channelName.value)
    .listen('.accrual', e => {
      store.commit('student/COUNT_STUDENT_LIKES', e.count)
    })

  return {
    likesChannel
  }
}
