import { fetchStudentLikes, fetchStudentCharacter } from '@/api/students'
import { fecthStudentActivity } from '@/api/profession'
import router from '@/router'
import { fetchItScores } from '@/api/itProjects'

const subject = JSON.parse(localStorage.getItem('subject')) || null

export default {
  namespaced: true,
  state: {
    subject: subject,
    status: false,
    character: null,
    likes: 0,
    profession: null,
    itScores: 0
  },
  getters: {
    isOnlineGamesAvaiable: (state) => {
      return state.likes >= 10
    },
    isShopAvaiable: (state) => {
      return state.likes >= 5
    },
    level: (state) => {
      if (!state.profession) return null

      const id = state.profession.student_profession.profession_id
      const prof = state.profession.professions.find(p => p.id === id)
      return prof ? prof.level : null
    }
  },
  mutations: {
    SET_STUDENT_SUBJECT(state, subject) {
      state.subject = subject,
      state.status = false
      state.likes = 0
      state.profession = null
      state.character = null

      if (subject) {
        localStorage.setItem('subject', JSON.stringify(subject))
      } else {
        localStorage.removeItem('subject')
      }
    },
    SET_STUDENT_CHARACTER(state, character) {
      state.character = character
    },
    SET_STUDENT_LIKES(state, likes) {
      state.likes = likes
    },
    SET_STUDENT_IT_SCORES(state, scores) {
      state.itScores = scores
    },
    COUNT_STUDENT_LIKES(state, likes) {
      state.likes += likes
    },
    SET_STUDENT_PROFESSION(state, profession) {
      state.profession = profession
    },
  },
  actions: {
    async saveSubject({ commit }, subject) {
      commit('SET_STUDENT_SUBJECT', subject)
    },
    async getSubjectData({ dispatch, rootState, state }, subject) {
      const userId = rootState.user.id
      dispatch('getCharacter')
      dispatch('getTrainers', subject.id, {root: true})
      dispatch('getLikes', {
        studentId: userId,
        subjectId: subject.id
      })

      if (subject.id === 1) {
        await dispatch('getProfession', userId)
      }

      state.status = true
    },
    async getCharacter({ commit, state, rootState }) {
      const userId = rootState.user.id
      const subjectId = state.subject.id

      const char = await fetchStudentCharacter({
        studentId: userId,
        subjectId: subjectId
      })
      if (!char && subjectId === 1) {
        router.push({name: 'StudentCharacterSelectView'})
        return
      }
      commit('SET_STUDENT_CHARACTER', char)
    },
    async getLikes(ctx, {studentId, subjectId}) {
      const likes = await fetchStudentLikes({studentId, subjectId})
      ctx.commit('SET_STUDENT_LIKES', likes)
    },
    async getProfession(ctx, id) {
      const profession = await fecthStudentActivity(id)

      if (!profession) {
        router.push({ name: 'StudentJobSelectView' })
        return
      }
      ctx.commit('SET_STUDENT_PROFESSION', profession)
    },
    async getItScores({commit, rootState}) {
      const userId = rootState.user.id
      const scores = await fetchItScores(userId)
      commit('SET_STUDENT_IT_SCORES', scores)
    },
  }
}
