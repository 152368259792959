import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import localizeText from '@/locales/localize';
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";
import moment from 'moment';

const updateAvatar = async (id, data) => {
  await HTTP.post(`/users/${id}/avatar`, data).then(res => {
    const image = res.data.url + '?' + Math.random()

    notify({
      title: localizeText('requestUserAvatarUpdate'),
      type: 'success'
    });
    store.commit('SET_USER_AVATAR', image)

    return image
  })
}

const deleteAvatar = async (id) => {
  HTTP.delete(`/users/${id}/avatar`).then(() => {
    notify({
      title: localizeText('requestUserAvatarDelete'),
      type: 'success'
    });
    store.commit('SET_USER_AVATAR', null)
  })
}

const createUser = async (data) => {
  const res = await HTTP.post('/users', data).then(res => res)

  if (res.status === 201) {
    notify({
      title: localizeText('requestUserCreateSuccess'),
      type: 'success'
    })
    return res.data
  }

  notify({
    title: localizeText('requestUserCreateError'),
    type: 'error'
  })
  return null
}

const updateUser = async ({id, user}) => {
  const res = await HTTP.patch(`/users/${id}`, user).then(res => res)

  if (res.status === 200) {
    notify({
      title: localizeText('requestUserUpdateSuccess'),
      type: 'success'
    })

    return res.data
  }

  notify({
    title: localizeText('requestUserUpdateError'),
    type: 'error'
  })
  return null
}

const updateStudentGuide = async (id, guide) => {
  await HTTP_NO_SPINNER.put(`/users/${id}/guides/${guide}`)
  store.commit('UPDATE_STUDENT_GUIDE', guide)
}

const fetchUserChats = async (id) => {
  const chats = await HTTP_NO_SPINNER.get(`/users/${id}/chats`).then(res => res.data)
  const user = store.state.user
  chats.forEach(c => {
    if (c.teacher) {
      c.target = c.teacher
    } else {
      c.target = c.from_id === user.id ? c.recipient : c.author
    }
  })
  return chats
}

const fetchChatMessages = async ({fromId, toId, page}) => {
  const res = await HTTP_NO_SPINNER.get(`users/${fromId}/chats/${toId}/messages`, {params: {page}}).then(res => res.data)
  res.data.sort((a, b) => moment(a.created_at, 'DD.MM.YYYY hh:mm:ss') - moment(b.created_at, 'DD.MM.YYYY hh:mm:ss'))
  return {
    arr: res.data,
    hasMore: res.next_page_url ? true : false
  }
}

const switchActivity = async (id) => {
  const resData = await HTTP.patch(`/users/${id}/activity`).then(res => res.data)
  if (resData.status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Успешно!" : 'Success!',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return resData.status
}

const loginToBilling = async (id) => {
  const link = await HTTP.get(`users/${id}/crm-link`).then(res => res.data)

  if (!link) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Что-то пошло не так" : 'Error',
      type: 'error'
    })
  }

  return link
}


export {
  updateAvatar,
  deleteAvatar,
  createUser,
  updateUser,
  updateStudentGuide,
  fetchChatMessages,
  fetchUserChats,
  switchActivity,
  loginToBilling
}
