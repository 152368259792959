import { HTTP_NO_SPINNER, HTTP } from '@/axios.config'
import localizeText from '@/locales/localize';
import { notify } from "@kyvg/vue3-notification";

const getMessages = async ({userId, subjectId}) => {
  return await HTTP_NO_SPINNER.get(`users/${userId}/messages`, { params: { subjectId } }).then(res => res.data)
}

const readMessage = async (messageId) => {
  return await HTTP_NO_SPINNER.patch(`/messages/${messageId}`).then(res => res.data.status)
}

const deleteMessages = async ({userId, subjectId}) => {
  return await HTTP.delete(`users/${userId}/messages`, { params: { subjectId } }).then(res => res.data)
}

const sendChatMessage = async (data) => {
  const res = await HTTP_NO_SPINNER.post(`/messages`, data).then(res => res.data)

  if (!res.status) {
    notify({
      title: localizeText('requestMessagesCreateError'),
      type: 'error'
    })
  }
}

const blockChat = async ({ teacherId, studentId }) => {
  const res = await HTTP.post(`/teachers/${teacherId}/chatblocks`, { student_id: studentId }).then(res => res)

  if (res.status == 201) {
    return res.data
  } else {
    notify({
      title: 'Не удалось заблокировать чат',
      type: 'error'
    })
    return null
  }
}

const unblockChat = async (id) => {
  const res = await HTTP.delete(`/chatblocks/${id}`).then(res => res)

  if (res.status == 200) {
    return true
  } else {
    notify({
      title: 'Не удалось разблокировать чат',
      type: 'error'
    })
    return false
  }
}

export {
  getMessages,
  readMessage,
  deleteMessages,
  sendChatMessage,
  blockChat,
  unblockChat
}
